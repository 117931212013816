import axios from "axios";
import { WebPubSubServiceClient } from "@azure/web-pubsub";
import { isObjectEmpty } from "@/util/index";
import store from '../store';

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: () => {
    // don't use exceptions to check status; we'll check return code manually
    return true;
  }
});

const msalTokenIsExpired = (expiryTime: string) => {
  const expiryTimeAsNumber = new Date(expiryTime).getTime();
  const currentTime = new Date().getTime();
  return (currentTime > expiryTimeAsNumber);
};

httpClient.interceptors.request.use((request) => {
  if (msalTokenIsExpired(localStorage.getItem('tokenExpiryTime') || "")) {
    store.dispatch("initiateLoggedInState");
  }
  return request;
}, (error) => {
  return Promise.reject(error);
});

httpClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // If request returns a 401, refresh token and then retry
    await store.dispatch("initiateLoggedInState");
    return new Promise((resolve, reject) => {
      httpClient.request(error.config).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  } else {
    return Promise.reject(error);
  }
});

export default {
  getOverview: async () => {
    try {
      const result = await httpClient.get('worklist/overview');
      return {
        success: result.status === 200,
        data: result.data
      };
    } catch (error) {
      return {
        success: false,
        message: error.toString()
      };
    }
  },
  getOverviewUpdateConnectionStr: async () => {
    try {
      const result = await httpClient.get('worklist/overview-refresh-connection');
      if (result.status === 200) {
        return result.data.connectionStr;
      }
    } catch (error) {
      return "";
    }
  },
  getStudies: async (service: string, status: string, eligibleOperator: string, limit: string | null) => {
    try {
      const params: any = { // eslint-disable-line
        service_id: service,
        status: status,
        eligible_operator: eligibleOperator
      };
      if (limit) {
        params.limit = limit;
      }
      const result = await httpClient.get('worklist', {
        params
      });

      if (result.status === 200) {
        if (result.data.studies) {
          return result.data.studies;
        } else {
          // TODO: this is the legacy API. We want to remove this ideally.
          return result.data;
        }
      }
    } catch (error) {
      return {
        error: error.toString()
      };
    }
  },
  reportStudy: async (accession: string, report_ae: string) => {
    const result = await httpClient.put("v1/" + accession, {
      status: "inprogress",
      report_ae
    });
    return result.status;
  },
  saveQCForm: async (accession: string, edit_point: string, qcReasons: object) => {
    const result = await httpClient.post(`v1/qc/${accession}/${edit_point}`, qcReasons);
    return result.status === 200;
  },
  updateStudyStatus: async (accession: string, status: string, escalatedReason: string, escalatedReasonText: string, escalatedBy: string, escalationDatetime: string) => {
    const result = await httpClient.put("v1/" + accession, {
      status: status,
      escalatedReason: escalatedReason,
      escalatedReasonText: escalatedReasonText,
      escalatedBy: escalatedBy,
      escalationDatetime: escalationDatetime
    });
    return result.status;
  },
  findStudy: async (accession: string) => {
    const result = await httpClient.get("v1/" + accession);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  },
  getOrderAPIHealthStatus: async () => {
    try {
      const result = await httpClient.get("v1/_status");
      return {
        success: result.status,
        message: result.data
      };
    } catch (error) {
      return {
        success: false,
        message: error.toString()
      };
    }
  },
  checkPubSubConnection: async () => {
    try {
      const result = await httpClient.get('worklist/overview-refresh-connection');
      let outputMsg = "";
      if (result.status === 200 && !isObjectEmpty(result.data)) {
        const service = new WebPubSubServiceClient(result.data.connectionStr, "pubsub");
        const token = await service.getClientAccessToken();
        const webSocket = new WebSocket(token.url);
        if (webSocket) {
          outputMsg = "PubSub Connection successfully established.";
          webSocket.close();
        } else {
          outputMsg = "PubSub Connection String present however, no connection could be established.";
        }
        return {
          success: result.status,
          message: outputMsg
        };
      } else {
        outputMsg = "API responded but no PubSub Connection String was returned, therefore no connection could be established.";
        return {
          success: false,
          message: outputMsg
        };
      }
    } catch (error) {
      return {
        success: false,
        message: "Unable to retrieve PubSub Connection String and initiate connection."
      };
    }
  },
  // sendEscalationReason: async(accession: string, reason: Object) => {
  //     Vue.prototype.$emit('http-request');
  //     const result = await httpClient.put("v1/" + accession, {
  //         accessionId: accession,
  //         reason: reason
  //     });
  //     return result.status == 201;
  // },
  setBaseUrl: (url) => {
    httpClient.defaults.baseURL = url;
  },
  setAuthenticationToken: (token: string) => {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  setClientPrincipal: (clientPrincipal: string) => {
    httpClient.defaults.headers['x-api-key'] = clientPrincipal;
  }
};
